import React from "react"
import Layout from "../../layouts/default"

import AudioPlayer from "../../components/audio-player"
import Breadcrumb from "../../components/breadcrumb"
import Constrain from "../../components/constrain"
import PageTitle from "../../components/page-title"
import Seo from "../../components/seo"
import Stack from "../../components/stack"

const Katalog18 = () => {
  return (
    <Layout backdrop="aussen">
      <Seo title="Friedrich Hölderlin: Der Frühling" />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Friedrich Hölderlin: Der Frühling",
                link: "/k/18",
              },
            ]}
          />
          <PageTitle>Friedrich Hölderlin: Der Frühling</PageTitle>
          <Constrain>
            <AudioPlayer
              src="katalog/18-christian-reiner-der-fruehling"
              title="Friedrich Hölderlin: Der Frühling – gelesen von Christian Reiner"
            />
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default Katalog18
